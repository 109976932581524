<template>
  <el-dialog
    :title="$t('Test Email')"
    width="50%"
    :visible.sync="isShow"
  >
    <el-form ref="mainForm">
      <el-alert class="mb-2" title="Input an email address for receiving the test email." type="success"></el-alert>

      <el-form-item :label="$t('Email Template')" prop="emailTemplateId">
        <el-select size="small" v-model="emailTemplateId">
          <el-option v-for="item in emailTemplates" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Organization Profile')" prop="organizationProfileId">
        <el-select size="small" v-model="organizationProfileId">
          <el-option v-for="item in organizationProfiles" :key="item.id" :label="item.companyName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Email')" prop="email">
        <el-input type="email" v-model="testEmail" size="small"/>
      </el-form-item>

      <div>
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" class="btn" @click="sendTestEmail()">
            {{ $t('Send') }}
          </b-button>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@/configs/apis";
import { BModal } from "bootstrap-vue";
import axios from "axios";

export default {
  components: { BModal },
  mounted() {
    this.loadEmailTemplates();
    this.loadOrganizationProfiles();
  },
  data() {
    return {
      isShow: false,
      emailTemplateId: null,
      organizationProfileId: null,
      testEmail: '',

      emailTemplates: [],
      organizationProfiles: [],
    }
  },
  methods: {
    show(profile, emailTemplate) {
      console.log(profile, emailTemplate);

      this.organizationProfileId = profile.id
      this.emailTemplateId = emailTemplate.id
      this.isShow = true
    },
    showFromEmailTemplate(emailTemplate) {
      this.emailTemplateId = emailTemplate.id
      this.isShow = true
    },

    loadEmailTemplates() {
      this.isLoading = true;

      axios.get(apis.erpEmailTemplates)
        .then(response => {
          this.emailTemplates = response.data.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    loadOrganizationProfiles() {
      this.isLoading = true;

      axios.get(apis.erpOrganizationProfile)
        .then(response => {
          this.organizationProfiles = response.data.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    sendTestEmail() {
      axios.post(
        apis.erpEmailTemplates + '/test',
        {organizationProfileId: this.organizationProfileId, emailTemplateId: this.emailTemplateId, email: this.testEmail}
      ).then(() => {
        this.promptInfo('Test Email sent success');
      }).catch(e => {
        this.promptError(e);
      })
    },
  }
}
</script>