<template>
  <div class="p-2">
    <b-row v-loading="isLoading">
      <b-col>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <EmailTestModal ref="emailTestView"/>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import apis from "@/configs/apis";
import EmailTestModal from "@/views/erp/components/EmailTestModal.vue";
import { EventBus } from "@/views/erp/utils/event-bus";
import InvoiceStatusTag from "@/views/erp/components/InvoiceStatusTag.vue";
import { getErpCan } from "@/utils/account-localstorage";
import File from "@/views/erp/mixins/File";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";
import { PROFILE_STATUSES } from "@/views/erp/utils/system";

export default {
  computed: {
    PROFILE_STATUSES() {
      return PROFILE_STATUSES
    }
  },
  mixins: [File, Prompt],
  mounted() {
  },
  data() {
    return {
      invoices: [],
      emailTemplates: [],
      currencyProfiles: [],
      isLoading: false,
    }
  },
  methods: {
    getErpCan,
  },
  components: {
    InvoiceStatusTag,
    EmailTestModal,
    BCard,
    BCardText,
  },
  props: {
    profile: Object,
  },
}
</script>

