<template>
  <AccrualList :profile="profile" :active-tab="activeTab"/>
</template>

<script>
import AccrualList from "@/views/erp/AccrualList.vue";

export default {
  components: {
    AccrualList,
  },
  props: {
    profile: Object,
    activeTab: String,
  }
}
</script>