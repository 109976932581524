<template>
  <div>
    <AuditTrailList :baseFilters="{objectId: profile.id, className: 'com.kerrylogistics.dashboard.entities.erp.VendorProfile', isProfile: true}"/>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import AdvanceTable from "@/views/components/advanceTable/advanceTable.vue";
import AuditTrailList from "@/views/erp/AuditTrailList.vue";

export default {
  components: {
    AuditTrailList,
    AdvanceTable,
    BCard,
    BCardText,
  },
  props: {
    profile: Object,
  },
}
</script>